import React from "react"
import { Package } from "./entities/package"

const Paket = ({ data, addItem }) => {
  if (!data) return null

  const BRONS = data?.find(item => item.sellerArticlePackageMasterId === 10160)
  const SILVER = data?.find(item => item.sellerArticlePackageMasterId === 10161)
  const GULD = data?.find(item => item.sellerArticlePackageMasterId === 10313)

  return (
    <div
      className="flex flex-col md:flex-row"
      style={{
        margin: "0",
        color: "#121212",
        textAlign: "left",
      }}
    >
      <Package
        data={BRONS}
        image={require("./../images/carBronze2.png")}
        onClick={() =>
          addItem({
            title: BRONS.descriptionHeader,
            sub: BRONS.descriptionHeader,
            price: BRONS.masterPrice,
            vat: BRONS.masterVat,
            id: BRONS.sellerArticlePackageMasterId,
          })
        }
      />
      <Package
        data={SILVER}
        image={require("./../images/carSilver2.png")}
        onClick={() =>
          addItem({
            title: SILVER.descriptionHeader,
            sub: SILVER.descriptionHeader,
            price: SILVER.masterPrice,
            vat: SILVER.masterVat,
            id: SILVER.sellerArticlePackageMasterId,
          })
        }
      />
      <Package
        data={GULD}
        image={require("./../images/carGold2.png")}
        onClick={() =>
          addItem({
            title: GULD.descriptionHeader,
            sub: GULD.descriptionHeader,
            price: GULD.masterPrice,
            vat: GULD.masterVat,
            id: GULD.sellerArticlePackageMasterId,
          })
        }
      />
    </div>
  )
}

export default Paket
