import React, { useEffect, useState } from "react"
import Layout from "../layout"
import SEO from "../components/seo"
import Paket from "../components/paket"
import PaketMC from "../components/paketMC"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons"
import { faMinusSquare } from "@fortawesome/free-solid-svg-icons"
import { useStaticQuery, Link } from "gatsby"
import { BookingButton } from "../components/atoms/Button"
import { Order } from "../components/formPage/FormPage"
import Kampanj from "../components/kampanj/Kampanj"
import PriceTabSwitcher from "../components/pricePage/PriceTabSwitcher"
import KampanjMoped from "../components/kampanj/KampanjMoped"
import KampanjMC from "../components/kampanj/KampanjMC"

const query = graphql`
  query {
    allPrismicPackage(filter: {}, sort: { fields: first_publication_date }) {
      edges {
        node {
          dataRaw
          id
        }
      }
    }
    allPrismicNyheterstartsida {
      edges {
        node {
          dataRaw
        }
      }
    }
    allPrismicPricebox {
      edges {
        node {
          dataRaw
        }
      }
    }
  }
`

const bil = [
  {
    title: "Enkel körlektion",
    sub: "40 minuter",
    price: 595,
  },
  {
    title: "Dubbel körlektion",
    sub: "2x40 minuter",
    price: 1190,
  },
  {
    title: "6 körlektioner",
    sub: "6x40 minuter (ord 3570kr)",
    price: 3395,
  },
  {
    title: "10 körlektioner",
    sub: "10x40 minuter (ord 5950kr)",
    price: 5695,
  },
  {
    title: "20 körlektioner",
    sub: "20x40 minuter (ord 11900kr)",
    price: 11495,
  },
  {
    title: "30 körlektioner",
    sub: "30x40 minuter (ord 17850kr)",
    price: 17195,
  },
  {
    title: "Körhäfte",
    sub: "Svenska",
    price: 150,
  },
  {
    title: "Körhäfte engelska",
    sub: "Engelska",
    price: 150,
  },
  {
    title: "Bokpaket på svenska",
    sub: "Svenska",
    price: 600,
  },
  {
    title: "Bokpaket på engelska",
    sub: "Engelska",
    price: 800,
  },
  {
    title: "Lån av bil vid körprov",
    sub: "Inklusive försäkringar",
    price: 1000,
  },
  {
    title: "Riskutbildning del 1",
    sub: "Obligatorisk utbildning",
    price: 700,
  },
  {
    title: "Riskutbildning del 2",
    sub: "Obligatoriska halkan",
    price: 2500,
  },
  {
    title: "Introduktionsutbildning",
    sub: "Handledarkurs",
    price: 595,
  },
  {
    title: "Webbtester",
    sub: "Obegränsat antal",
    price: 500,
  },
  {
    title: "Teorilektion",
    sub: "En timme enskild privatlektion i körkortsteori",
    price: 800,
  },
  {
    title: "Administrationsavgift",
    sub: "Inskrivning och administrationsavgift",
    price: 250,
  },
  {
    title: "Syntest",
    sub: "",
    price: 150,
  },
]

// load from file in data folder
const bil2 = require("../data/bilProducts.json")

/*
nya MC:
10 körlektioner 5*55min
9900:- nu 9500:-
  Rabatt 400:-

  16 körlektioner 8*55min
15840:- nu 14900:-
  Rabatt 940:-

  22 körlektioner 11*55min
21780:- nu 20500:-
  Rabatt 1280:-*/

const mc = [
  {
    title: "Enkel körlektion",
    sub: "55 minuter",
    price: 990,
  },
  {
    title: "MC körlektion - dubbel",
    sub: "Vi kör alltid dubbellektion, 55min (990kr) x 2",
    price: 1980,
  },
  {
    title: "MC körlektioner - 10st",
    sub: "5 dubbellektioner, ord pris 9900:-, rabatt 400:-",
    price: 9500,
  },
  {
    title: "MC körlektioner - 16st",
    sub: "8 dubbellektioner, ord pris 15840:-, rabatt 940:-",
    price: 14900,
  },
  {
    title: "MC körlektioner - 22st",
    sub: "11 dubbellektioner, ord pris 21780:-, rabatt 1280:-",
    price: 20500,
  },
  {
    title: "MC Bokpaket",
    sub: "Bokpaket för MC",
    price: 800,
  },
  {
    title: "Lån av kläder",
    sub: "Enstaka tillfälle, gäller bara för våra elever",
    price: 150,
  },
  {
    title: "Riskutbildning del 1",
    sub: "Obligatorisk utbildning MC",
    price: 900,
  },
  {
    title: "Riskutbildning del 2",
    sub: "Obligatorisk utbildning MC",
    price: 3400,
  },
  {
    title: "Banavgift",
    sub: "Manövreringsbanan (engångskostnad)",
    price: 600,
  },
  {
    title: "Körprov / Lån av MC",
    sub: "Gäller skolans elever",
    price: 2500,
  },
  {
    title: "Körprov / Lån av MC",
    sub: "För privata personer",
    price: 3600,
  },
]
const mc2 = require("../data/mcProducts.json")

const Priser = ({ location }) => {
  const {
    allPrismicPackage,
    allPrismicNyheterstartsida,
    allPrismicPricebox,
  } = useStaticQuery(query)

  const priceBox = allPrismicPricebox.edges[0].node.dataRaw
  const [currentTab, setCurrentTab] = useState(location.state?.page || "CAR")
  const [items, setItems] = useState([])
  const [isCar, setIsCar] = useState(false)

  useEffect(() => {
    setIsCar(false)
  }, [currentTab])

  const addItem = item => {
    setItems(() => {
      const newItems = [...items, item]
      return newItems
    })
  }

  const removeItem = index => {
    setItems(() => {
      const newItems = items.filter((item, ind) => {
        return index !== ind
      })
      return newItems
    })
  }

  const transformItemsToBooking = () => ({
    orderState: {
      items,
    },
    orderType: Order.CART,
    car: isCar,
  })

  return (
    <Layout>
      <SEO title="Priser" />
      <div
        style={{
          backgroundColor: "#FAFAFA",
          padding: "0",
          color: "#666",
        }}
      >
        <br />
        <h2 className="cen">Våra priser</h2>
        <p style={{ padding: "0 2em" }}>
          På denna sida kan du läsa om våra nuvarande priser för paket och
          lektioner. Genom att klicka och addera ihop artiklar kan du se ett
          beräknat pris för din utbildning nere i rutan till höger.
          <i style={{ fontWeight: "bold", marginLeft: 8 }}>
            Ny prislista nedan gäller från och med 20/1/2022
          </i>
        </p>

        <PriceTabSwitcher active={currentTab} onSwitch={setCurrentTab} />

        {currentTab === "CAR" && (
          <section>
            <Paket
              data={bil2}
              addItem={item => {
                addItem(item)
                setIsCar(true)
              }}
            />
            <br />
            <h2 className="cen">Priser - Bil</h2>
            <ul
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                backgroundColor: "white",
                margin: "0",
                color: "#222",
              }}
            >
              {bil2.map((item, index) => (
                <li
                  className={`listItem is-mobile hoverStyle`}
                  style={{ width: "100%", margin: "0", padding: "0.35em" }}
                  onClick={() => {
                    addItem({
                      title: item.descriptionHeader,
                      sub: item.descriptionHeader,
                      price: item.masterPrice,
                      vat: item.masterVat,
                      id: item.sellerArticlePackageMasterId,
                    })
                    setIsCar(true)
                  }}
                  key={item.sellerArticlePackageMasterId}
                >
                  <div className="flex-1 p-2">
                    <strong>{item.descriptionHeader}</strong>
                  </div>
                  <div className="flex-1 column is-one-third">
                    {item.descriptionHeader}
                  </div>
                  <div
                    className="flex-1 is-one-fifth"
                    style={{ textAlign: "right" }}
                  >
                    {item.masterPrice} kr
                  </div>
                  <div className="flex justify-center w-8">
                    <FontAwesomeIcon
                      className="hoverStyle"
                      icon={faPlusSquare}
                    />
                  </div>
                </li>
              ))}
            </ul>
          </section>
        )}

        {currentTab === "MC" && (
          <section>
            <PaketMC data={mc2} addItem={item => addItem(item)} />
            <br />
            <h2 className="cen">Priser - MC</h2>
            <ul
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                backgroundColor: "white",
                margin: "0",
                color: "#222",
              }}
            >
              {mc2.map((item, index) => (
                <li
                  className={`listItem is-mobile hoverStyle`}
                  style={{ width: "100%", margin: "0", padding: "0.35em" }}
                  onClick={() => {
                    addItem({
                      title: item.descriptionHeader,
                      sub: item.descriptionHeader,
                      price: item.masterPrice,
                      vat: item.masterVat,
                      id: item.sellerArticlePackageMasterId,
                    })
                    setIsCar(true)
                  }}
                  key={item.sellerArticlePackageMasterId}
                >
                  <div className="flex-1 p-2">
                    <strong>{item.descriptionHeader}</strong>
                  </div>
                  <div className="flex-1 column is-one-third">
                    {item.descriptionHeader}
                  </div>
                  <div
                    className="flex-1 is-one-fifth"
                    style={{ textAlign: "right" }}
                  >
                    {item.masterPrice} kr
                  </div>
                  <div className="flex justify-center w-8">
                    <FontAwesomeIcon
                      className="hoverStyle"
                      icon={faPlusSquare}
                    />
                  </div>
                </li>
              ))}
            </ul>
          </section>
        )}
        {currentTab === "MOPED" && (
          <>
            <KampanjMoped priceNum={6_200} data={priceBox.mopedkurs} />
            <section className="mx-auto max-w-xs mt-12 pr-4">
              <h2>AM - Körkort för moped klass 1</h2>
              <p>
                Moped klass I eller AM-moped får köras i max 45 km/h. Du kör i
                trafiken och får inte använda cykelbanorna. När utbildningen är
                klar får du ett körkort. Du behöver ha ett giltigt
                körkortstillstånd för att få lov att börja övningsköra.
              </p>

              {/* <h2>Viktig information för moped klass 1 (AM)</h2>
              <p>
                Du behöver gå en 8 timmars lärarledd teoriutbildning. Efter att
                du gått teoriutbildningen kommer du att behöva plugga
                självständigt inför ditt teoriprov. Detta gör du via den
                digitala plattformen "köra moped", där får du tillgång till
                teori, instuderingsfrågor, övningar, bilder och filmer - allt
                för resan mot körkort.
              </p>
              <p>
                Om du tycker att det är svårt att läsa eller om du behöver ha
                extra stöd så berätta det för oss så vi kan hjälpa dig.
              </p>
              <p>
                Då det är långa väntetider på Trafikverket för att få skriva
                teoriprov så rekommenderar vi att ni bokar in ett teoriprov så
                snart som möjligt, tidigast 2 veckor efter den digitala teorin.
                Teoriprovet behöver vara betalt senast 25 timmar före provet för
                att behålla tiden, detta sker till Trafikverket.
              </p> */}
              <h3>Körlektioner</h3>
              <p>
                Du behöver ha minst 4 timmars körlektion på moped med en av våra
                lärare. Utbildningen leds av trafikskolans mc instruktörer med
                mångårig erfarenhet som trafiklärare.
              </p>
              <p>
                Du kommer först att lära dig att manövrera mopeden i låg och hög
                fart. Du övar bromsteknik på olika underlag upp till 45km/h.
                Därefter kommer du att få köra i olika trafikmiljöer. Du kommer
                bland annat att lära dig: vart på vägen du ska vara placerad,
                att anpassa hastigheten till olika trafiksituationer och förutse
                eventuella risker.
              </p>
              <p>
                Om du och din lärare inte tycker att det räcker med 4 timmars
                körlektion så finns det möjlighet att köra mer.
              </p>
              <h3>För att få göra provet behöver du uppfölja dessa krav</h3>
              <ol className="flex flex-col justify-start items-start whitespace-pre ml-0 text-xs">
                <li>Du har ett körkortstillstånd</li>
                <li>Du är permanent bosatt i Sverige</li>
                <li>
                  Du har gått en mopedutbildning hos en behörig utbildare.
                </li>
                <li>Du har fyllt 15 år.</li>
                <li>Du har gjort ett godkänt kunskapsprov på Trafikverket.</li>
                <li>Du har inget körkort från något annat EES-land.</li>
              </ol>
              <a href="https://transportstyrelsen.se/sv/vagtrafik/Korkort/ta-korkort/moped-snoskoter-terranghjuling-och-traktor/am-korkort-for-moped-klass-i/">
                <p>Läs mer på transportstyrelsens hemsida</p>
              </a>
            </section>
          </>
        )}

        <div
          style={{
            position: "fixed",
            right: "0px",
            bottom: "2em",
            background: "white",
            padding: "1em",
            border: "1px solid #eee",
            borderRight: "0",
            borderRadius: "8px 0 0 8px",
            opacity: "0.9",
            color: "white",
            backgroundColor: "#333",
            zIndex: "100000",
            fontSize: "1em",
            textAlign: "right",
          }}
        >
          <ul
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "right",
              alignContent: "stretch",
            }}
          >
            {items.map((item, index) => (
              <li
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  width: "100%",
                }}
              >
                <p style={{ margin: "0", width: "120px", fontSize: "0.8em" }}>
                  {item.title}
                </p>
                <FontAwesomeIcon
                  style={{ margin: "0 0.4em" }}
                  icon={faMinusSquare}
                  onClick={() => removeItem(index)}
                />
              </li>
            ))}
          </ul>

          <h6 style={{ userSelect: "none", fontSize: "1.2em", color: "#AAA" }}>
            Totalt:{" "}
            <span style={{ fontSize: "1.2em", color: "#FFF" }}>
              {items && items.reduce((sum, item) => sum + item.price, 0)} kr
            </span>
            <Link to={"/formular"} state={transformItemsToBooking()}>
              <div
                css={BookingButton}
                style={{ margin: "16px", float: "right" }}
              >
                Boka!
              </div>
            </Link>
          </h6>
        </div>
        <br />
      </div>
    </Layout>
  )
}

export default Priser
