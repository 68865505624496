import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons"

export const formatThousands = num =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")

export const Package = ({ data, image, onClick }) => {
  const {
    descriptionHeader: title,
    lineItems: includes,
    masterPrice: price,
    masterPrice: oldPrice,
  } = data || {}

  return data ? (
    <div
      className="column is-one-third is-centered"
      style={{
        color: "#121212",
        padding: "2em",
      }}
      onClick={onClick}
    >
      <img className="hoverCar" src={image} alt="bronsbil" />
      <h3 style={{ margin: "0.5em 0" }}>{title}</h3>
      <ul
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "0",
          alignItems: "start",
        }}
        className="hoverText"
      >
        {includes.map(include => (
          <li key={include.sellerArticlePackageDetailId}>
            {include.detailDescription}
          </li>
        ))}
        <br />
        <li className="price-controls">
          <div>
            <h2>{formatThousands(price)}:- </h2>
            <p className="strike-through">
              (ord pris {formatThousands(oldPrice)}:-)
            </p>
          </div>
          <FontAwesomeIcon
            className="hoverStyle"
            size="2x"
            icon={faPlusSquare}
          />
        </li>
      </ul>
    </div>
  ) : null
}
