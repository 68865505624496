import styles from "./PriceTabSwitcher.module.css"
import { cx } from "@emotion/css"

const PriceTabSwitcher: React.FC<{
  active: string
  onSwitch: (tab: string) => void
}> = ({ active, onSwitch }) => {
  return (
    <div className={styles.PriceTabSwitcher}>
      <span
        onClick={() => onSwitch("CAR")}
        className={cx({ [styles.active]: active === "CAR" }, styles.switch)}
      >
        Bil 🚗
      </span>
      <span
        onClick={() => onSwitch("MC")}
        className={cx({ [styles.active]: active === "MC" }, styles.switch)}
      >
        MC 🏍
      </span>
      <span
        onClick={() => onSwitch("MOPED")}
        className={cx({ [styles.active]: active === "MOPED" }, styles.switch)}
      >
        MOPED 🏍
      </span>
    </div>
  )
}
export default PriceTabSwitcher
